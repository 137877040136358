export const faqsData = [
  {
    title: 'I don’t have any background knowledge in programming, can I apply?',
    content:
      'Yes, you can. You don’t need any background knowledge in IT or coding before you can register. Having one is only an advantage for you. At Enoch Louis EBT, we start from the basics and guide you through all you need to know to be a successful software developer.',
    id: 'list1',
    faqNum: '01',
  },
  {
    title: 'What Must I Do To Be Successful In The Program?',
    content:
      'Be aware that your success is our priority. To be successful in the program, you must get yourself fully involved by not missing lesson periods, actively obeying instructors, mentors and even classmates. You must also practice constantly and execute real live projects.',
    id: 'list',
    faqNum: '02',
  },
  {
    title: 'What Materials Do I Need For This Program?',
    content:
      'For your training, you need a laptop (Mac, divnus or PC) with a minimum of 4GB ram, and a storage capacity of at least 250GB.Every other software will be installed with the help of our experts before the beginning of the course.',
    id: 'list3',
    faqNum: '03',
  },
  {
    title: 'What Are My Expectations At The End Of The Program?',
    content:
      'By the end of this course must have: Completed several individual and group projects, Learned about software development from our experienced developers, Be equipped with all the foundational knowledge needed for your new career.',
    id: 'list4',
    faqNum: '04',
  },
  {
    title:
      'I have basic knowledge in software development already. Do you have an advanced package for me?',
    content:
      'Yes, at Enoch Louis EBT, we offer advanced training to people who have completed a course(s) in programming, but wish to learn more.',
    id: 'list5',
    faqNum: '05',
  },
  {
    title: 'Can we register for courses online via the website?',
    content:
      'Yes. Payments are made to Enoch Louis EBT official accounts through any of Mobile payments, electronic bank transfers, USSD or bank teller system.',
    id: 'list6',
    faqNum: '06',
  },
  {
    title: 'Is Enoch Louis EBT of Programming government owned?',
    content:
      'Enoch Louis EBT of Programming is a private owned company, approved by government.',
    id: 'list7',
    faqNum: '07',
  },
  {
    title: 'How Much Is Course fee?',
    content: `Course fee is variant, depending on the course one decides to go for. Visit tech skills page for details.`,
    id: 'list8',
    faqNum: '08',
  },
  {
    title: 'Is there any other payment aside course fee?',
    content: 'No, there is no other payment',
    id: 'list9',
    faqNum: '09',
  },
  {
    title: 'What are the courses offered',
    content:
      'The courses available across our centers are: Fullstack Web Development, Data Analysis, Artificial Intelligence, and Cybersecurity etc.',
    id: 'list10',
    faqNum: '10',
  },
  {
    title: 'Who can enroll?',
    content:
      'Anyone between the ages of 10-65, irrespective of educational status can enrol',
    id: 'list11',
    faqNum: '11',
  },
  {
    title: 'Is certificate given upon graduation',
    content: 'Yes, a certificate is issued upon graduation',
    id: 'list12',
    faqNum: '12',
  },
];
